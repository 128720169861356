import coverImg from "assets/images/v107/bg.webp"
import titleImg from "assets/images/v107/title.webp"
import mainImg from "assets/images/v107/main.webp"

export const v107Config = {
  cover: coverImg,
  title: {
    en: titleImg,
  },
  picture: mainImg,
}
