import headerImgEn from "assets/images/v101/header-en.webp"
import infoImgEn from "assets/images/v101/info-en.webp"

export const v101ConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
  },
  info: {
    en: infoImgEn,
  },
}
