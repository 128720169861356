import headerImgEn from "assets/images/v71/header-en.webp"
import infoImgEn from "assets/images/v71/info-en.webp"
import headerImgFr from "assets/images/v71/header-fr.webp"
import infoImgFr from "assets/images/v71/info-fr.png"
import headerImgDe from "assets/images/v71/header-de.webp"
import infoImgDe from "assets/images/v71/info-de.webp"
import headerImgNl from "assets/images/v71/header-nl.webp"
import infoImgNl from "assets/images/v71/info-nl.webp"
import headerImgPt from "assets/images/v71/header-pt.webp"
import infoImgPt from "assets/images/v71/info-pt.webp"
import headerImgTr from "assets/images/v71/header-tr.webp"
import infoImgTr from "assets/images/v71/info-tr.webp"
import headerImgEs from "assets/images/v71/header-es.webp"
import infoImgEs from "assets/images/v71/info-es.webp"
import headerImgIt from "assets/images/v71/header-it.webp"
import infoImgIt from "assets/images/v71/info-it.webp"
import headerImgHi from "assets/images/v71/header-hi.webp"
import infoImgHi from "assets/images/v71/info-hi.webp"

export const v71ConfigImages: Record<string, Record<string, string>> = {
  header: {
    en: headerImgEn,
    fr: headerImgFr,
    de: headerImgDe,
    nl: headerImgNl,
    pt: headerImgPt,
    tr: headerImgTr,
    es: headerImgEs,
    it: headerImgIt,
    hi: headerImgHi,
  },
  info: {
    en: infoImgEn,
    fr: infoImgFr,
    de: infoImgDe,
    nl: infoImgNl,
    pt: infoImgPt,
    tr: infoImgTr,
    es: infoImgEs,
    it: infoImgIt,
    hi: infoImgHi,
  },
}
