import coverImg from "assets/images/v49/cover.webp"
import titleImg from "assets/images/v49/title.webp"
import titleDeImg from "assets/images/v49/title-de.webp"
import titleFrImg from "assets/images/v49/title-fr.webp"
import titleHiImg from "assets/images/v49/title-hi.webp"
import { generateAppsFlyerLink } from "lib/common"
import { FormattedMessage } from "react-intl"
import { useEffect } from "react"
import { sendAmplitudeEvent } from "lib/amplitude"
import { trackMetaEvent } from "lib/meta"
import { trackSnapchatClick } from "lib/snapchat"
import { sendGoogleClickConversion } from "lib/google"
import { useParams } from "react-router-dom"
import { twMerge } from "tailwind-merge"

type PageWithCoverImages = {
  cover?: string
  title?: Record<string, string>
  picture?: string
}

type PageWithCoverProps = {
  surveyId: string
  images?: PageWithCoverImages
  theme?: {
    button?: {
      className?: string
    }
  }
}

const images: PageWithCoverImages = {
  cover: coverImg,
  title: {
    en: titleImg,
    de: titleDeImg,
    fr: titleFrImg,
    hi: titleHiImg,
  },
}

export function PageWithCover(props: PageWithCoverProps) {
  const imagesList = props.images || images
  const { locale = "en" } = useParams()
  const titleImage = imagesList.title?.[locale]

  const onButtonClick = () => {
    trackMetaEvent("Lead")
    trackSnapchatClick()
    sendGoogleClickConversion(() => {
      location.assign(generateAppsFlyerLink(props.surveyId))
    })
  }

  useEffect(() => {
    sendAmplitudeEvent("onboarding_started", {
      onboarding: props.surveyId,
    })
    document.body.classList.add(`cover-${props.surveyId}`)
  }, [])

  return (
    <div
      className="relative max-w-[450px] h-full mx-auto pb-[50px] bg-cover bg-no-repeat bg-center"
      style={
        imagesList.cover
          ? { backgroundImage: `url(${imagesList.cover})` }
          : { background: "rgb(28, 23, 24)" }
      }
    >
      <div className="absolute bottom-[50px] left-0 right-0 px-4">
        {imagesList.picture && (
          <img
            src={imagesList.picture}
            className="max-w-[300px] mx-auto mb-2"
          />
        )}
        {titleImage && <img src={titleImage} />}
        <button
          className={twMerge(
            "bg-[#FF00FF] text-white w-full h-[56px] text-[18px] uppercase font-sans-bold tracking-wide",
            props.theme?.button?.className,
          )}
          onClick={onButtonClick}
        >
          <FormattedMessage id="button.download" />
        </button>
      </div>
    </div>
  )
}
